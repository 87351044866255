export const GlobalFunctions = {
  data() {
    return {};
  },
  methods: {
    currencyFormat(value) {
      const cur = localStorage.getItem("selectedCurrency");
      const sym = cur;
      return value.replace(/Currency/g, sym);
    },
  },
};
